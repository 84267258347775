
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions, httpsCallable } from 'firebase/functions';




// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCwZUYTm1SAbPknZBqxVsvJALFG8oxNxhQ",
  authDomain: "creative-monkey-ecosystem.firebaseapp.com",
  projectId: "creative-monkey-ecosystem",
  storageBucket: "creative-monkey-ecosystem.appspot.com",
  messagingSenderId: "850348107694",
  appId: "1:850348107694:web:823c19802f0f47fb1eca3f",
  measurementId: "G-SZ7339ZYGB"
};

  

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const cloud = getStorage(app)
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);
const functions = getFunctions(app);

const contactFunction = httpsCallable(functions, 'contact');
const welcomeFunction = httpsCallable(functions, 'welcome');




export { app, cloud, analytics, auth, db, functions, contactFunction, welcomeFunction };