import React from 'react'
import { Column, Row } from '../../layout/components/dom'
import { useGlobal } from '../../utility/CTX'
import { useState } from 'react';
import { useEffect } from 'react';
import { useSession } from '../../utility/Auth';
import { collection, query, where, getDocs, serverTimestamp, getFirestore } from "firebase/firestore"; 
import { IButton } from '../../layout/components/button';




function Feedback({setTab}) {
    const { theme } = useGlobal();
    const [tickets, setTickets] = useState([]);
    const [selected, setSelected] = useState(null);

    const session = useSession();


    useEffect(() => {
        // load the documents in the "feedback" collection where userId is === to 
        if (session.user) {
            let db = getFirestore();

            const fetchFeedback = async () => {
                try {
                    // Reference the "feedback" collection
                    const feedbackCollection = collection(db, "feedback");
                    
                    // Create a query to filter by userId
                    const feedbackQuery = query(feedbackCollection, where("userId", "==", session.user.uid));
                    
                    // Execute the query
                    const querySnapshot = await getDocs(feedbackQuery);
                    
                    // Process the results
                    const feedbackData = querySnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
                    
                    console.log("User Feedback:", feedbackData);
                    setTickets(feedbackData);
    
                    // Optionally set the feedback data to state if you have a state variable
                    // setFeedback(feedbackData);
                } catch (error) {
                    console.error("Error fetching feedback:", error);
                }
            };
    
            fetchFeedback();

        }
    }, [session.user]);


    // load the feedback tickets 
    // that are associated with this account



    console.log(tickets);



    return <Column style={{maxWidth: 800}}>
        {selected && <SelectedTicket ticket={selected} setTicket={setSelected} />}
        {!selected && <Row style={{flexWrap: 'wrap'}}>
            {tickets.map((tick, i) => <Column key={'ticket-'+tick?.id+session?.user?.id} style={{cursor: 'pointer', overflow: 'hidden', background: theme.bg2, borderRadius: 5, margin: 5}} onClick={() => setSelected(tick)}>
                <Row style={{padding: '0px 5px', background: theme.bg3}}>
                    <div>{tick?.timestamp?.toDate().toLocaleString()}</div>
                </Row>
                <p style={{fontSize: '0.9em', padding: '10px', margin: '0px'}}>{tick.content}</p>
                <Row style={{justifyContent: 'end', padding: '0px 5px', fontSize: '0.9em'}}>
                    <div style={{marginRight: 5}}>{tick?.comments?.length || 0}</div>
                    <IButton value='comment' />
                </Row>
            </Column>)}
        </Row>}
    </Column>
}




const SelectedTicket = ({ ticket, setTicket }) => {
    const { theme } = useGlobal();



    return <Column style={{maxWidth: 800, flexGrow: 1}}>
        <Row style={{margin: '10px auto', alignItems: 'center', color: theme.red, cursor: 'pointer', padding: '0px 5px', borderRadius: 5, background: theme.bg2}} onClick={() => setTicket(null)}>
            <div style={{marginRight: 5}}>Go Back</div>
            <IButton value='undo' colored={theme.red} />
        </Row>

        <Row style={{flexWrap: 'wrap', flexGrow: 1}}>
            <Column style={{background: theme.bg2, borderRadius: 5, margin: '5px'}}>
                {/** this is metadata */}
                <Row style={{padding: '0px 5px', alignItems: 'center'}}>
                    <div>{ticket?.timestamp?.toDate().toLocaleString()}</div>

                </Row>

            </Column>

            <Column style={{flexGrow: 1, margin: '5px 10px', background: theme.bg2, borderRadius: 5}}>
                <Row style={{padding: '0px 5px', alignItems: 'center'}}>
                    <div style={{flexGrow: 1}}>Comments</div>
                    <IButton value='comment' />
                </Row>
                {/** comments */}
            </Column>
        </Row>
    </Column>
}






export default Feedback